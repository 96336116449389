import * as React from "react";
import { Helmet } from "react-helmet";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from "@mui/material";
import BlogHome from "./BlogHome";

import EmailTwoToneIcon from "@mui/icons-material/EmailTwoTone";
import Footer from "../../components/Footer";
import PhoneInTalkTwoToneIcon from "@mui/icons-material/PhoneInTalkTwoTone";
import Navbar from "../../components/Navbar";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link } from "react-router-dom";
import { useEffect } from "react";

import InputBase from "@mui/material/InputBase";
import { styled, alpha } from "@mui/material/styles";
import SearchIcon from "../../assets/icons/SearchIcon.tsx";
import { Padding } from "@mui/icons-material";
import { client } from "../../client.js";

interface HelpCenterProps {

}

const HelpCenter: React.FC<HelpCenterProps> = () => {
  const theme = useTheme();

  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  // const desktop = useMediaQuery(theme.breakpoints.down("lg"));

  const Search = styled("div")(({ theme }) => ({
    position: "relative",

    backgroundColor: alpha(theme.palette.common.white, 1),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.95),
    },
    marginLeft: 0,
    marginTop: "2.5rem",
    width: "100% !important",
    height: "4rem",
    borderRadius: "9999px",
    display: "flex",
    padding: "8px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      padding: "0 30px",
      width: "100%",
    },
  }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    //   padding: theme.spacing(0, 2),
    height: "80%",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "3rem",
    zIndex: "100",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    flex: 1,
    height: "100%",
    borderRadius: "9999px",
    zIndex: "1",
    "& .MuiInputBase-input": {
      zIndex: "1",
      padding: theme.spacing(1, 1, 1, 0),
      height: "100%",
      fontSize: "1.25rem",
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      [theme.breakpoints.up("sm")]: {
        width: "100%",
        "&:focus": {
          width: "100%",
        },
      },
    },
  }));
  const [posts, setPosts] = React.useState([]);
  
  const handleClick = () => {
    client
      .fetch(
        `*[_type in ["post", 'author', 'category']
                && (
                title match $queryString + '*'
                || name match $queryString + '*')]
                | order(publishedAt desc){
                    title,
                    name,
                    bio,
                    body,
                    'slug' : slug.current,
                    description,
                    'type': _type,
                    }
                `
      )
      .then((data: any) => console.log(data))
      .catch(console.error);
  };

  

  return (
    <>
      <Helmet>
        <title>Help Center</title>
        <meta
          name="description"
          content="Have a question about using Herewith or becoming a Helper? We’re here to help! Browse our FAQs, guides, and articles. Or, get in touch at support@herewith.com"
        />
      </Helmet>
      <Box
        sx={{
          width: "100vw",
          height: "fit-content",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0 ",
        }}
      >
        {/* header */}

        <Box
          sx={{
            width: phone ? "100vw" : "100%",
            height: phone ? "fit-content" : "35rem",
            backgroundColor: "#DBF7FF",
            paddingBottom: "1rem",
            margin: 0,
          }}
        >
          <Navbar color="black" />

          <Box
            sx={{
              width: phone ? "100%" : "90%",
              height: phone ? "fit-content" : "65%",
              flex: 1,
              display: "flex",
              flexDirection: phone ? "column" : "row",
              justifyContent: phone ? "center" : "space-between",
              margin: phone ? 0 : "0 auto",
              alignItems: phone ? "center" : "flex-start",
            }}
          >
            <Box
              sx={{
                width: phone ? "90%" : "65%",
                height: phone ? "fit-content" : "100%",
                borderRadius: "2rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: phone ? "center" : "space-around",

                backgroundColor: "#ACE0EF",
                textAlign: phone ? "center" : "left",
                padding: phone ? "2rem 0" : tablet ? "2rem " : "5rem",
                alignItems: phone ? "center" : "flex-start",

                margin: phone ? "0 auto " : 0,
              }}
            >
              <h1
                style={{
                  fontSize: "10vw",
                  marginBottom: phone ? "2rem" : "none",
                  display: phone ? "block" : "none",
                }}
              >
                How can we <br />
                <span style={{ color: "#C97B99", fontStyle: "italic" }}>
                  help you
                </span>
                ?
              </h1>
              <h1
                style={{
                  fontSize: tablet ? "2.5rem" : "3rem",
                  marginBottom: phone ? "2rem" : "none",
                  display: phone ? "none" : "block",
                }}
              >
                How can we
                <span style={{ color: "#C97B99", fontStyle: "italic" }}>
                  {" "}
                  help you
                </span>
                ?
              </h1>
              {/*<Search>
                <SearchIconWrapper onClick={handleClick}>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  style={{ font: "0.1px" }}
                  placeholder="Search for advice and articles"
                  inputProps={{ "aria-label": "search" }}
                />
              </Search>*/}
            </Box>

            <Box
              sx={{
                width: phone ? "100%" : tablet ? "40%" : "30%",
                height: "100%",
                padding: phone ? "1rem" : 0,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "left",
              }}
            >
              <List
                sx={{ width: "100%", maxWidth: 360, bgcolor: "transparent" }}
              >
                <ListItem key="1">
                  <ListItemText
                    sx={{ display: phone ? "none" : "block" }}
                    primaryTypographyProps={{
                      fontFamily: "Sitebody !important",
                    }}
                    primary={
                      <p
                        style={{
                          color: "#115C72",
                          fontSize: "1.2rem",
                          fontFamily: "Sitebody",
                          fontWeight: "600",
                        }}
                      >
                        Contact our <br />{" "}
                        <span style={{ color: "#6AA08F" }}>Support team</span>
                      </p>
                    }
                    secondary=""
                  />
                  <ListItemText
                    sx={{ display: phone ? "block" : "none" }}
                    primaryTypographyProps={{
                      fontFamily: "Sitebody !important",
                    }}
                    primary={
                      <p style={{ fontSize: "1.2rem" }}>
                        Contact our Support team
                      </p>
                    }
                    secondary=""
                  />
                </ListItem>

                <ListItem key="2">
                  <Link to="tel:4155069776">
                    <ListItemAvatar>
                      <Avatar sx={{ backgroundColor: "#ACE0EF" }}>
                        <PhoneInTalkTwoToneIcon sx={{ color: "#115C72" }} />
                      </Avatar>
                    </ListItemAvatar>
                  </Link>
                  <Link to="tel:4155069776">
                    <ListItemText
                      primaryTypographyProps={{
                        fontFamily: "Sitebody !important",
                      }}
                      sx={{ color: "#115C72" }}
                      primary="Call Mon-Sat, 9AM-6:30PM PST"
                      secondary="415-506-9776"
                    />
                  </Link>

                  {/* <ListItemText
                    primaryTypographyProps={{ fontFamily: "Sitebody !important" }}
                    sx={{ color: "#115C72" }}
                    primary="Call Mon-Sat, 9AM-6:30PM PST"
                    secondary="415-506-9776"
                  /> */}
                </ListItem>
                <ListItem>
                  <Link to="mailto:support@herewith.com">
                    <ListItemAvatar>
                      <Avatar sx={{ backgroundColor: "#ACE0EF" }}>
                        <EmailTwoToneIcon sx={{ color: "#115C72" }} />
                      </Avatar>
                    </ListItemAvatar>
                  </Link>
                  <Link to="mailto:support@herewith.com">
                    <ListItemText
                      primaryTypographyProps={{
                        fontFamily: "Sitebody !important",
                      }}
                      sx={{ color: "#115C72" }}
                      primary="Send us an email"
                      secondary="support@herewith.com"
                    />
                  </Link>
                </ListItem>
              </List>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "6rem",
            display: "flex",
            borderBottom: "1px solid lightgray",
            padding: "1rem",
            marginBottom: "2rem",
          }}
        ></Box>
        <BlogHome />
        <Footer />
      </Box>
    </>
  );
};

export default HelpCenter;
